import React, { useState, useEffect } from 'react';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import NavContentContainer from '@artemis/components/NavBar/NavContentContainer';
import { getIsEmbedded } from '@artemis/store/embed/selectors';
import { isSendEventSupported, sendEvent } from './webviewHooks';
import CloseWebviewModal from './CloseWebviewModal';
import { EMBEDDED_EVENTS } from './webviewHooks/constants';

const StyledContentContainer = styled(NavContentContainer)`
  box-shadow: inset 0px -1px 0px #e8e9ee;
  height: 45px;
  min-height: 45px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  align-items: baseline;
`;

const CloseButton = styled.button`
  background: transparent;
  cursor: pointer;
  border: none;
  position: absolute;
  left: -10px;
`;

const Title = styled.p`
  ${props => props.theme.typography.body};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
`;

const EmbedNavigationControls = () => {
  const isEmbedded = useSelector(getIsEmbedded);
  const [sendEventSupported, setSendEventSupported] = useState(false);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);

  useEffect(() => {
    setSendEventSupported(isSendEventSupported());
  }, []);

  if (!isEmbedded || !sendEventSupported) {
    return null;
  }

  return (
    <>
      <StyledContentContainer>
        <Container>
          <CloseButton
            onClick={() => setIsCloseModalOpen(true)}
            data-testid="close-webview-button"
          >
            <ArrowBackIosRoundedIcon
              sx={{ color: 'black', fontSize: 16, verticalAlign: 'middle' }}
            />
          </CloseButton>
          <Title>
            <FormattedMessage entry="global.embed.title" />
          </Title>
        </Container>
      </StyledContentContainer>
      <CloseWebviewModal
        isOpen={isCloseModalOpen}
        onClose={() => setIsCloseModalOpen(false)}
        onConfirm={() =>
          sendEvent({ eventName: EMBEDDED_EVENTS.WEBVIEW_CLOSE })
        }
      />
    </>
  );
};

export default EmbedNavigationControls;
