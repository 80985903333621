import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

Modal.setAppElement('#__next');

const CustomModal = ({ className, children, overlay = {}, ...props }) => {
  const theme = useContext(ThemeContext);
  return (
    <Modal
      className={className}
      {...props}
      style={{
        content: {
          boxShadow: theme.shadows.shadow0,
          zIndex: theme.zIndices.modal,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(0, 0, 0, 0.3)',
          zIndex: theme.zIndices.overlay,
          ...overlay,
        },
      }}
    >
      {children}
    </Modal>
  );
};

CustomModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  overlay: PropTypes.shape({}),
};

export default CustomModal;
