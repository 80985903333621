import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import EmbedNavigationControls from '@artemis/integrations/embed/EmbedNavigationControls';
import NavContentContainer from './NavContentContainer';

const Nav = styled.nav`
  width: 100%;
  background-color: white;
  padding: 0;
  z-index: ${props => props.theme.zIndices.docked};
  position: relative;
  box-shadow: ${props => props.theme.shadows.shadowN1};
  background: ${({ theme }) => theme.palette.white};
  justify-content: space-between;
`;

const NavBar = ({ className, children, fullWidth = false }) => (
  <Nav role="navigation" className={className}>
    <EmbedNavigationControls />
    <NavContentContainer fullWidth={fullWidth}>{children}</NavContentContainer>
  </Nav>
);

NavBar.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default NavBar;
