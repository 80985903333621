/**
 *
 * ResponsiveImage
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getResourceSet } from '@artemis/store/resourceSet/selectors';
import { breakpoints } from '@artemis/theme/breakpoints';

function ResponsiveImage({ id, className, specificAlt }) {
  try {
    const resources = useSelector(getResourceSet);
    const image = resources[id];
    if (!image) {
      return id;
    }

    const { mobile, tablet, desktop, alt, title } = image;

    return (
      <picture className={className}>
        <source
          media={`(min-width: ${breakpoints.tabletMax}px)`}
          srcSet={desktop}
        />
        <source
          media={`(min-width: ${breakpoints.mobileMax}px)`}
          srcSet={tablet}
        />
        <img // eslint-disable-line @next/next/no-img-element
          style={{ width: '100%', height: '100%' }}
          src={mobile}
          alt={specificAlt || alt}
          title={title}
        />
      </picture>
    );
  } catch (e) {
    return id;
  }
}

ResponsiveImage.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  specificAlt: PropTypes.string,
};

export default ResponsiveImage;
