import React from 'react';
import PropTypes from 'prop-types';
import StandardModal from '@artemis/components/StandardModal';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import styled from 'styled-components';

const Title = styled.h2`
  font-weight: ${props => props.theme.typography.fontWeightBold};
  font-size: 1.25rem;
  text-align: center;
  padding-bottom: 10px;
`;

const Content = styled.p`
  text-align: center;
  padding-bottom: 0.5rem;
`;

const CloseWebviewModal = ({ isOpen, onConfirm, onClose }) => (
  <StandardModal
    onClose={onClose}
    isOpen={isOpen}
    primaryButtonText={
      <FormattedMessage entry="global.embed.leaveWebviewModal.confirm" />
    }
    onClickPrimaryButton={onConfirm}
    secondaryButtonText={
      <FormattedMessage entry="global.embed.leaveWebviewModal.cancel" />
    }
    onClickSecondaryButton={onClose}
    mobileFullHeight={false}
  >
    <Title>
      <FormattedMessage entry="global.embed.leaveWebviewModal.title" />
    </Title>
    <Content>
      <FormattedMessage entry="global.embed.leaveWebviewModal.description" />
    </Content>
  </StandardModal>
);

CloseWebviewModal.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

export default CloseWebviewModal;
