import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const NAV_BAR_HEIGHT = 60;

const Container = styled.div`
  height: ${NAV_BAR_HEIGHT}px;
  min-height: ${NAV_BAR_HEIGHT}px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: left;
  align-content: center;
  justify-content: space-between;
  z-index: 1;
`;

const AlignmentWrapper = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  ${({ theme }) => theme.isTablet`
    margin: 0 auto;
    width: ${fullWidth => (fullWidth ? '100%' : '1236px')};
    padding: 0 30px;
  `}
`;

const NavContentContainer = ({ className, children, fullWidth }) => (
  <Container className={className}>
    <AlignmentWrapper fullWidth={fullWidth}>{children}</AlignmentWrapper>
  </Container>
);

NavContentContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
};

NavContentContainer.defaultProps = {
  fullWidth: false,
};

export default NavContentContainer;
