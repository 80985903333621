import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

const Metadata = ({
  title,
  description,
  canonical,
  stylesheet, // Strongly discouraged since loading won't be optimized
  openGraph,
  twitter,
  noindex = false,
  nofollow = false,
  appleItunesApp,
}) => (
  <Head>
    {canonical && <link rel="canonical" href={canonical} key="canonical" />}
    {title && <title key="title">{title}</title>}
    {stylesheet && <link rel="stylesheet" href={stylesheet} key="stylesheet" />}
    {description && (
      <meta name="description" content={description} key="description" />
    )}
    {openGraph?.title && (
      <meta property="og:title" content={openGraph?.title} key="og-title" />
    )}
    {openGraph?.description && (
      <meta
        property="og:description"
        content={openGraph?.description}
        key="og-description"
      />
    )}
    {openGraph?.image?.url && (
      <meta
        property="og:image"
        content={openGraph?.image?.url}
        key="og-image"
      />
    )}
    {openGraph?.image?.alt && (
      <meta
        property="og:image:alt"
        content={openGraph?.image?.alt}
        key="og-image-alt"
      />
    )}
    {openGraph?.siteName && (
      <meta
        property="og:site_name"
        content={openGraph?.siteName}
        key="og-site_name"
      />
    )}
    <meta property="og:type" content="website" key="og-type" />
    {openGraph?.url && (
      <meta property="og:url" content={openGraph?.url} key="og-url" />
    )}
    {twitter?.title && (
      <meta name="twitter:title" content={twitter?.title} key="twitter-title" />
    )}
    {twitter?.description && (
      <meta
        name="twitter:description"
        content={twitter?.description}
        key="twitter-description"
      />
    )}
    {twitter?.image?.url && (
      <meta
        name="twitter:image"
        content={twitter?.image?.url}
        key="twitter-image"
      />
    )}
    {twitter?.image?.alt && (
      <meta
        name="twitter:image:alt"
        content={twitter?.image?.alt}
        key="twitter-image-alt"
      />
    )}
    {twitter?.card && (
      <meta name="twitter:card" content={twitter?.card} key="twitter-card" />
    )}
    {(noindex || nofollow) && (
      <meta
        name="robots"
        content={[
          noindex ? 'noindex' : 'index',
          nofollow ? 'nofollow' : 'follow',
        ].join(',')}
        key="robots"
      />
    )}
    {
      // https://developer.apple.com/documentation/webkit/promoting_apps_with_smart_app_banners
      appleItunesApp && (
        <meta
          name="apple-itunes-app"
          content={appleItunesApp}
          key="apple-itunes-app"
        />
      )
    }
  </Head>
);

Metadata.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  canonical: PropTypes.string,
  stylesheet: PropTypes.string,
  openGraph: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    siteName: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }),
  }),
  twitter: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }),
    card: PropTypes.string,
  }),
  noindex: PropTypes.bool,
  nofollow: PropTypes.bool,
  appleItunesApp: PropTypes.string,
};

export default Metadata;
