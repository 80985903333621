import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { lighten } from 'polished';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Modal from '@artemis/components/Modal';
import Loading from '@artemis/components/Loading';
import { MODAL_SIZES } from '@artemis/utils/constants';

const ModalWrapper = styled(Modal)`
  width: 100%;
  background-color: ${props => props.theme.palette.background.card};
  border-radius: 4pt;
  height: 100%;
  overflow-y: auto;
  :focus {
    outline: none;
  }
  ${props =>
    !props.mobileFullHeight &&
    css`
      height: auto;
      transform: translateY(-50%);
      margin: 50vh auto;
      max-width: 316px;
    `}

  ${({ theme, size }) => theme.isTablet`
    max-width: ${MODAL_SIZES[size]};
    height: auto;
    max-height: 100%;
    margin: 0 auto;
    margin: 50vh auto;
    transform: translateY(-50%);
  `}
`;

const CloseModal = styled.button`
  z-index: 1;
  background: ${props => props.theme.palette.common.white};
  box-shadow: ${props => props.theme.shadows.shadow1};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  top: 14px;
  left: 14px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  position: absolute;
  ${({ theme }) => theme.isTablet`
    top:30px;
    left:30px;
  `}
`;

const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: ${({ isClosable }) => (isClosable ? '70px' : '33px')} 16px 16px;
  box-sizing: border-box;
  justify-content: space-between;
  height: 100%;
  ${({ theme }) => theme.noScrollbars};
  ${({ theme, isClosable }) => theme.isTablet`
    border-radius: 4pt;
    padding: ${isClosable ? '70px' : '33px'} 32px 32px;
  `}
`;

const ModalContent = styled.div`
  ${({ fixedControls }) =>
    fixedControls &&
    css`
      overflow-y: scroll;
      ${({ theme }) => theme.noScrollbars};
    `};
`;

const ActionsContainer = styled.div`
  ${props =>
    props.hasFooterBorder &&
    css`
      border-top: solid 1px ${props.theme.rtColors.ui5};
    `}

  padding: 1rem 0 0;
`;

const StyledButton = styled.button`
  width: 100%;
  background-color: ${props => props.theme.palette.primary};
  color: ${props => props.theme.palette.common.white};
  ${props => props.theme.typography.button};

  text-decoration: none;
  display: flex;
  justify-content: center;
  min-width: 160px;
  box-sizing: border-box;
  padding: 10px 24px;
  border-radius: 4pt;
  border: none;
  cursor: pointer;
  transition: 0.25s background-color ease;

  ${props =>
    props.$isPrimaryButtonGrayscale &&
    `
    background-color: ${props.theme.rtColors.black100};
    color: ${props.theme.rtColors.black1000};
  `}

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
  &:hover {
    background-color: ${props =>
      !props.disabled &&
      props.theme.palette.hover.onLightBg(
        props.$isPrimaryButtonGrayscale
          ? props.theme.rtColors.black100
          : props.theme.palette.primary,
      )};
  }
`;

const OutlinedButton = styled(StyledButton)`
  background-color: white;
  border: 1px solid ${props => props.theme.palette.primary};
  color: ${props => props.theme.palette.primary};
  margin-top: 12px;
  &:hover {
    background-color: ${props => lighten(0.51, props.theme.palette.primary)};
  }
`;

const GhostButton = styled(OutlinedButton)`
  border: none;
`;

const WarningButton = styled(OutlinedButton)`
  background-color: ${props => props.theme.rtColors.black100};
  border: none;
  color: ${props => props.theme.rtColors.red500};
  &:hover {
    background-color: ${props => props.theme.rtColors.red100};
  }
`;

const StandardModal = ({
  onClose,
  closeButtonIcon,
  primaryButtonText,
  onClickPrimaryButton,
  secondaryButtonText,
  onClickSecondaryButton,
  secondaryButtonType = 'outline',
  mobileFullHeight = true,
  children,
  hasFooterBorder = false,
  isLoading = false,
  isClosable = true,
  isPrimaryButtonGrayscale = false,
  isPrimaryButtonDisabled = false,
  size = 'md',
  fixedControls = false,
  ...rest
}) => (
  <ModalWrapper
    onRequestClose={onClose}
    {...rest}
    mobileFullHeight={mobileFullHeight}
    size={size}
  >
    <ModalContainer isClosable={isClosable}>
      <Loading isLoading={isLoading} />
      {isClosable && (
        <CloseModal onClick={onClose} data-testid="modal-close">
          {closeButtonIcon || (
            <CloseIcon sx={{ color: 'black', fontSize: 20 }} />
          )}
        </CloseModal>
      )}
      <ModalContent fixedControls={fixedControls}>{children}</ModalContent>
      {(primaryButtonText || secondaryButtonText) && (
        <ActionsContainer hasFooterBorder={hasFooterBorder}>
          {primaryButtonText && (
            <StyledButton
              onClick={onClickPrimaryButton}
              data-testid="modal-primary-button"
              $isPrimaryButtonGrayscale={isPrimaryButtonGrayscale}
              disabled={isPrimaryButtonDisabled}
            >
              {primaryButtonText}
            </StyledButton>
          )}
          {secondaryButtonText && secondaryButtonType === 'outline' && (
            <OutlinedButton
              onClick={onClickSecondaryButton}
              data-testid="modal-secondary-button"
            >
              {secondaryButtonText}
            </OutlinedButton>
          )}
          {secondaryButtonText && secondaryButtonType === 'ghost' && (
            <GhostButton
              onClick={onClickSecondaryButton}
              data-testid="modal-secondary-button"
            >
              {secondaryButtonText}
            </GhostButton>
          )}
          {secondaryButtonText && secondaryButtonType === 'warning' && (
            <WarningButton
              onClick={onClickSecondaryButton}
              data-testid="modal-secondary-button"
            >
              {secondaryButtonText}
            </WarningButton>
          )}
        </ActionsContainer>
      )}
    </ModalContainer>
  </ModalWrapper>
);

StandardModal.propTypes = {
  /** Show/hide of the modal */
  isOpen: PropTypes.bool,
  /** The function to be called when clicks on the close button */
  onClose: PropTypes.func,
  closeButtonIcon: PropTypes.node,
  /** The text to be rendered on the primary button. If neither this nor secondaryButtonText is provided, the footer won't be rendered */
  primaryButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** The function to be called when clicks on the primary button */
  onClickPrimaryButton: PropTypes.func,
  /** The text to be rendered on the secondary button. If neither this nor primaryButtonText is provided, the footer won't be rendered */
  secondaryButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** The function to be called when clicks on the secondary button */
  onClickSecondaryButton: PropTypes.func,
  /** The type of the secondary button */
  secondaryButtonType: PropTypes.oneOf(['outline', 'ghost', 'warning']),
  /** Whether to render full height modal on mobile */
  mobileFullHeight: PropTypes.bool,
  /** Whether to render top border for footer */
  hasFooterBorder: PropTypes.bool,
  /** Modal content */
  children: PropTypes.node,
  /** The loading status */
  isLoading: PropTypes.bool,
  /** Whether the modal has a close button */
  isClosable: PropTypes.bool,
  /** Whether the primary button should be black and white themed */
  isPrimaryButtonGrayscale: PropTypes.bool,
  /** Whether the primary button is disabled */
  isPrimaryButtonDisabled: PropTypes.bool,
  /** the size of the modal */
  size: PropTypes.oneOf(Object.keys(MODAL_SIZES)),
  /** Whether the close button and controls should be fixed, while the content scrolls */
  fixedControls: PropTypes.bool,
};

export default StandardModal;
